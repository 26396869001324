<template>
  <div>
    <v-card elevation="2" outlined shaped tile class="mb-2">
      <v-card-text>
        <v-row class="calendar_filter_bar">
          <v-col cols="12" sm="4" md="2">
            <v-menu
              ref="start_date_menu"
              v-model="start_date_menu"
              :close-on-content-click="false"
              :return-value.sync="filterCriteria['start_date']"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="filterCriteria['start_date']"
                  v-bind:label="$t('brands.start_date')"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  @change="onChangeData"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filterCriteria['start_date']"
                type="month"
                no-title
                scrollable
                :locale="this.$i18n.locale"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="start_date_menu = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="onChangeStartDate">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="4" md="2">
            <v-menu
              ref="end_date_menu"
              v-model="end_date_menu"
              :close-on-content-click="false"
              :return-value.sync="filterCriteria['end_date']"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="filterCriteria['end_date']"
                  v-bind:label="$t('brands.end_date')"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filterCriteria['end_date']"
                type="month"
                no-title
                scrollable
                :locale="this.$i18n.locale"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="end_date_menu = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="onChangeEndDate">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="4" md="2">
            <v-autocomplete
              v-model="filterCriteria['users']"
              v-bind:label="$t('User')"
              item-text="username"
              item-value="id"
              :items="userlist"
              hide-no-data
              filled
              dense
              multiple
              clearable
              @change="onChangeData"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" md="2">
            <v-autocomplete
              v-model="filterCriteria['parents']"
              v-bind:label="$t('expensesParentUserName')"
              :items="parents"
              item-text="username"
              item-value="id"
              hide-no-data
              filled
              dense
              multiple
              clearable
              @change="onChangeData"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" sm="4" md="2">
            <v-autocomplete
              v-model="filterCriteria['department']"
              v-bind:label="$t('salesforce.department')"
              :items="departments"
              hide-no-data
              filled
              dense
              multiple
              clearable
              @change="onChangeData"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" md="2">
            <v-autocomplete
              v-model="filterCriteria['routes']"
              v-bind:label="$t('route')"
              item-text="name"
              item-value="id"
              :items="routes"
              hide-no-data
              filled
              dense
              multiple
              clearable
              @change="onChangeData"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" md="2">
            <v-autocomplete
              v-model="filterCriteria['projects']"
              v-bind:label="$t('project')"
              v-bind:placeholder="$t('start_typing_to_search')"
              item-text="name"
              item-value="id"
              :search-input.sync="search_projects"
              :loading="isProjectsSearching"
              :items="filtered_projects"
              hide-no-data
              filled
              dense
              multiple
              clearable
              @change="onChangeData"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" md="2">
            <v-autocomplete
              v-model="filterCriteria['zones']"
              v-bind:label="$t('zone')"
              v-bind:placeholder="$t('start_typing_to_search')"
              item-text="name"
              item-value="id"
              :search-input.sync="search_zones"
              :loading="isZonesSearching"
              :items="filtered_zones"
              hide-no-data
              filled
              dense
              multiple
              clearable
              @change="onChangeData"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" md="2">
            <v-autocomplete
              v-model="filterCriteria['companyId']"
              v-bind:label="$t('Company')"
              :items="companies"
              item-text="name"
              item-value="id"
              hide-no-data
              filled
              dense
              multiple
              clearable
              @change="onChangeData"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col cols="12" sm="12" md="12" class="calendar_category_row">
            <div class="color_category">
              <div class="colored_section calendar-body-cell-weekend">
                &nbsp;
              </div>
              <span class="category_name">{{ $t("Weekend") }}</span>
            </div>
            <div class="color_category">
              <div
                class="colored_section calendar-body-cell-static-publicholiday"
              >
                &nbsp;
              </div>
              <span class="category_name">{{ $t("Public Holiday") }}</span>
            </div>
            <div class="color_category">
              <div class="colored_section calendar-body-cell-holiday-approved">
                &nbsp;
              </div>
              <span class="category_name">{{ $t("Vacations Aproved") }}</span>
            </div>
            <div class="color_category">
              <div
                class="colored_section calendar-body-cell-holiday-pendingapproval"
              >
                &nbsp;
              </div>
              <span class="category_name">{{
                $t("Vacations Pending Aproval")
              }}</span>
            </div>
            <div class="color_category">
              <div class="colored_section calendar-body-cell-paidleave">
                &nbsp;
              </div>
              <span class="category_name">{{ $t("paidleave") }}</span>
            </div>
            <div class="color_category">
              <div class="colored_section calendar-body-cell-leaves-opened">
                &nbsp;
              </div>
              <span class="category_name">{{ $t("Leave Open") }}</span>
            </div>
            <div class="color_category">
              <div class="colored_section calendar-body-cell-leaves-closed">
                &nbsp;
              </div>
              <span class="category_name">{{ $t("Leave Closed") }}</span>
            </div>
            <div class="color_category">
              <div class="colored_section calendar-body-cell-user-notactive">
                &nbsp;
              </div>
              <span class="category_name">{{ $t("Not Active") }}</span>
            </div>
            <div class="color_category">
              <v-checkbox
                @change="activeUserUpdated"
                v-model="filterCriteria['activeUser']"
                :label="$t('Active User')"
              ></v-checkbox>
            </div>
            <div class="color_category">
              <v-checkbox
                @change="activeSeeAll"
                v-model="filterCriteria['seeall']"
                :label="$t('All Employees')"
              ></v-checkbox>
            </div>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col cols="12" sm="12" md="12">
            <ve-table
              id="calendar-table"
              fixed-header
              max-height="calc(100vh - 330px)"
              :columns="columns"
              :table-data="tableData"
              :border-around="true"
              :border-x="true"
              :border-y="true"
              :scroll-width="1500"
              :sort-option="sortOption"
              :cell-style-option="cellStyleOption"
            />
            <div v-show="showEmpty" class="empty-data">Data Empty.</div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import download from "js-file-download";
import moment from "moment";
import _ from "lodash";

export default {
  name: "Leaves",
  data() {
    return {
      companies: [],
      departments: [],
      projects: [],
      parents: [],
      userlist: [],
      routes: [],
      filterCriteria: {
        users: [],
        parents: [],
        department: [],
        project: [],
        zones: [],
        start_date: new Date().toISOString().substr(0, 7),
        end_date: new Date().toISOString().substr(0, 7),
        activeUser: true,
        seeall: false,
        holiday_count: {},
        leaves_count: {},
      },
      filterable_col_list: ["holiday_count", "leave_count"],
      start_date_menu: false,
      end_date_menu: false,

      tableData: [],
      totaltableData: 0,
      /********** vue-easytable options **********/
      loadingInstance: null,
      sorting: null,
      sortOption: {
        sortChange: (params) => {
          this.sortChange(params);
        },
      },
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          if (this.filterable_col_list.includes(column.field)) {
            return "filterable-column " + column.field;
          }
        },
        bodyCellClass: ({ row, column, rowIndex }) => {
          if (row.dates && row.dates[column.field]) {
            if (row.dates[column.field].isWeekend) {
              return "calendar-body-cell-weekend";
            }
            if (!row.dates[column.field].isActive) {
              return "calendar-body-cell-user-notactive";
            }
            if (
              row.dates[column.field].isStaticPublicHoliday ||
              row.dates[column.field].isUserPublicHoliday
            ) {
              return "calendar-body-cell-static-publicholiday";
            }
            if (row.dates[column.field].isLeaveOpened) {
              return "calendar-body-cell-leaves-opened";
            }
            if (row.dates[column.field].isLeaveClosed) {
              return "calendar-body-cell-leaves-closed";
            }
            if (row.dates[column.field].isPaidLeave) {
              return "calendar-body-cell-paidleave";
            }
            if (row.dates[column.field].isHolidayApproved) {
              return "calendar-body-cell-holiday-approved";
            }
            if (row.dates[column.field].isHolidayPendingApproval) {
              return "calendar-body-cell-holiday-pendingapproval";
            }
          }
        },
      },
      showEmpty: false,
      defaultColumns: [
        {
          title: "",
          fixed: "left",
          children: [
            {
              key: "username",
              title: this.$t("salesforce.UserName"),
              field: "username",
              align: "left",
              width: 200,
              fixed: "left",
              sortBy: "",
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <span class="text-bold">
                    {row.user && row.user.name + " " + row.user.surname}
                  </span>
                );
              },
            },
            {
              key: "route_name",
              title: this.$t("route"),
              field: "route_name",
              align: "left",
              width: 150,
              fixed: "left",
              sortBy: "",
            },
            {
              key: "project_name",
              title: this.$t("project"),
              field: "project_name",
              align: "left",
              width: 150,
              fixed: "left",
              sortBy: "",
            },
            {
              key: "zone_name",
              title: this.$t("zone"),
              field: "zone_name",
              align: "left",
              width: 150,
              fixed: "left",
              sortBy: "",
            },
            {
              key: "holiday_count",
              title: "Vacaciones",
              field: "holiday_count",
              align: "center",
              width: 130,
              fixed: "left",
              sortBy: "",
              filterCustom: {
                defaultVisible: false,
                render: ({ showFn, closeFn }, h) => {
                  return (
                    <div class="custom-filter">
                      <input
                        type="number"
                        class="filter_date_picker"
                        v-model={this.filterCriteria["holiday_count"]["from"]}
                        value={this.filterCriteria["holiday_count"]["from"]}
                        placeholder="From - "
                      />
                      <br />
                      <input
                        type="number"
                        class="filter_date_picker"
                        v-model={this.filterCriteria["holiday_count"]["to"]}
                        value={this.filterCriteria["holiday_count"]["to"]}
                        placeholder="To - "
                      />
                      <div class="custom-filter-operation">
                        <v-btn
                          color="blue darken-1"
                          text
                          on-click={() =>
                            this.searchCancel(closeFn, "holiday_count")
                          }
                        >
                          {this.$t("cancel")}
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          on-click={() => this.searchConfirm(closeFn)}
                        >
                          {this.$t("Search")}
                        </v-btn>
                      </div>
                    </div>
                  );
                },
              },
            },
            {
              key: "leaves_count",
              title: "Bajas",
              field: "leaves_count",
              align: "center",
              width: 130,
              fixed: "left",
              sortBy: "",
              filterCustom: {
                defaultVisible: false,
                render: ({ showFn, closeFn }, h) => {
                  return (
                    <div class="custom-filter">
                      <input
                        type="number"
                        class="filter_date_picker"
                        v-model={this.filterCriteria["leaves_count"]["from"]}
                        value={this.filterCriteria["leaves_count"]["from"]}
                        placeholder="From - "
                      />
                      <br />
                      <input
                        type="number"
                        class="filter_date_picker"
                        v-model={this.filterCriteria["leaves_count"]["to"]}
                        value={this.filterCriteria["leaves_count"]["to"]}
                        placeholder="To - "
                      />
                      <div class="custom-filter-operation">
                        <v-btn
                          color="blue darken-1"
                          text
                          on-click={() =>
                            this.searchCancel(closeFn, "leaves_count")
                          }
                        >
                          {this.$t("cancel")}
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          on-click={() => this.searchConfirm(closeFn)}
                        >
                          {this.$t("Search")}
                        </v-btn>
                      </div>
                    </div>
                  );
                },
              },
            },
          ],
        },
      ],
      columns: [],
      search_projects: null,
      isProjectsSearching: false,
      filtered_projects: [],
      search_zones: null,
      isZonesSearching: false,
      filtered_zones: [],
    };
  },
  watch: {
    async search_projects(val) {
      this.search_brands(val);
    },
    async search_zones(val) {
      this.search_zone_list(val);
    },
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated", "isGPV", "user"]),
  },
  methods: {
    searchCancel(closeFn, cancelFilterKey) {
      closeFn();
      this.filterCriteria[cancelFilterKey] = {};
      this.getDataFromApi();
    },
    searchConfirm(closeFn) {
      closeFn();
      this.getDataFromApi();
    },
    async search_zone_list(val) {
      this.isZonesSearching = true;
      let fetch_url = "expenses/analysis/get_zones?filter_name=" + val;
      const resp = await ApiService.get(fetch_url);
      this.filtered_zones = resp.data;
      this.isZonesSearching = false;
    },
    async search_brands(val) {
      this.isProjectsSearching = true;
      let fetch_url = "expenses/analysis/get_projects?filter_name=" + val;
      const resp = await ApiService.get(fetch_url);
      this.filtered_projects = resp.data;
      this.isProjectsSearching = false;
    },
    show() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#calendar-table"),
        name: "flow", // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },
    close() {
      this.loadingInstance.close();
    },
    sortChange(params) {
      this.sorting = params;
      this.getDataFromApi();
    },
    getQueryParams() {
      let query_params_string = "";
      let query_params = [];
      if (this.sorting !== null) {
        for (const [key, value] of Object.entries(this.sorting)) {
          if (value !== null && value !== "") {
            query_params.push("sortby=" + key);
            query_params.push(
              "sortdesc=" + (value === "desc" ? "true" : "false")
            );
          }
        }
      }
      let filterModel = [];
      for (const [key, value] of Object.entries(this.filterCriteria)) {
        if (key === "activeUser") {
          filterModel.push({
            columnField: key,
            filterValue: value,
          });
        } else if (key === "seeall") {
          filterModel.push({
            columnField: key,
            filterValue: value,
          });
        } else if (value !== "" && value !== null) {
          if (Object.keys(value).length > 0) {
            filterModel.push({
              columnField: key,
              filterValue: value,
            });
          }
        }
      }
      let filter_columns = document.querySelectorAll(
        "#calendar-table .filterable-column"
      );
      for (let i = 0; i < filter_columns.length; i++) {
        filter_columns[i].classList.remove("filter_active");
      }
      query_params_string += query_params.join("&");
      if (filterModel.length > 0) {
        query_params_string +=
          "&filterModel=" + encodeURIComponent(JSON.stringify(filterModel));
      }
      return query_params_string;
    },
    async getDataFromApi() {
      this.show();
      let query_params_string = this.getQueryParams();
      let fetch_url = "holiday/calendar";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      try {
        const resp = await ApiService.get(fetch_url);
        this.generate_new_cols(resp.monthsOfPeriod, resp.daysOfPeriod);
        this.tableData = resp.data;
        if (this.tableData.length > 0) {
          this.showEmpty = false;
        } else {
          this.showEmpty = true;
        }
        this.close();
      } catch (error) {
        this.close();
      }
    },
    generate_new_cols(monthsOfPeriod, daysOfPeriod) {
      this.column = [];
      this.columns = [...this.defaultColumns];
      if (this.sorting && Object.keys(this.sorting).length > 0) {
        let col_sorting = null;
        for (const [key, value] of Object.entries(this.sorting)) {
          if (value !== "") {
            col_sorting = {
              key,
              value,
            };
          }
        }
        console.log("col_sorting - ", col_sorting);
        for (let i = 0; i < this.columns[0].children.length; i++) {
          if (
            col_sorting !== null &&
            col_sorting.key === this.columns[0].children[i].key
          ) {
            this.columns[0].children[i].sortBy = col_sorting.value;
          } else {
            this.columns[0].children[i].sortBy = "";
          }
        }
      }
      for (const year_month of monthsOfPeriod) {
        let month_push_item = {
          title: year_month,
          key: year_month,
          children: [],
        };
        let [cur_year, cur_month] = year_month.split("-");
        let date_list = [];
        for (let i = 1; i <= moment(year_month, "YYYY-MM").daysInMonth(); i++) {
          let current_date = year_month + "-" + (i >= 10 ? i : "0" + i);
          let date_push_item = {
            key: current_date,
            title: i,
            field: current_date,
            align: "center",
            width: 40,
          };
          month_push_item.children.push(date_push_item);
        }
        this.columns.push(month_push_item);
      }
    },
    isStartDateGreaterThanEndDate() {
      let start_date = this.filterCriteria["start_date"].split("-");
      let end_date = this.filterCriteria["end_date"].split("-");
      if (parseInt(start_date[0]) > parseInt(end_date[0])) {
        this.filterCriteria["end_date"] = this.filterCriteria["start_date"];
      } else if (
        parseInt(start_date[0]) === parseInt(end_date[0]) &&
        parseInt(start_date[1]) > parseInt(end_date[1])
      ) {
        this.filterCriteria["end_date"] = this.filterCriteria["start_date"];
      }
    },
    onChangeStartDate(date) {
      this.$refs.start_date_menu.save(this.filterCriteria["start_date"]);
      this.isStartDateGreaterThanEndDate();
      this.onChangeData();
    },
    onChangeEndDate(date) {
      this.$refs.end_date_menu.save(this.filterCriteria["end_date"]);
      this.isStartDateGreaterThanEndDate();
      this.onChangeData();
    },
    async onChangeData() {
      this.getDataFromApi();
    },
    async activeUserUpdated(newValue) {
      this.getDataFromApi();
    },
    async activeSeeAll(newValue) {
      this.getDataFromApi();
    },
    async init() {
      try {
        let get_calendar_search_params_url =
          "holiday/calendar/get_calendar_search_params";
        const response = await ApiService.get(get_calendar_search_params_url);
        this.companies = response.companies;
        this.departments = response.departments;
        this.filtered_projects = response.projects;
        this.filtered_zones = response.zones;
        this.parents = response.parents;
        this.userlist = response.userlist;
        this.routes = response.routes;
        this.onChangeData();
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style>
.calendar_category_row {
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 10px;
  row-gap: 10px;
}
.color_category {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.color_category .colored_section {
  width: 30px;
  height: 30px;
  border: 1px solid;
}
.color_category span.category_name {
  margin-left: 10px;
  color: #000;
}
</style>
